// @flow strict

import styles from './About.module.css';

import squirrel from './gouache-squirrel.jpg';

const About = () => (
  <section className={styles.section}>
    <img
      className={styles.img}
      src={squirrel}
      alt="A gouache drawing of a cute furry orange squirrel holding a flower"
    />
    <article className={styles.article}>
      <p>
        Hi!
        <br />
        I'm an illustrator and designer based in Canada.
      </p>
      <p>
        After 6 years studying in animation, I chose to focus on illustration for children. With over 10 years of
        experience in drawing, I'm looking forward to telling unique stories through my work.
      </p>
      <p>
        I enjoy: bread{' '}
        <span role="img" aria-label="bread">
          🍞
        </span>
        , coffee{' '}
        <span role="img" aria-label="coffee">
          ☕
        </span>{' '}
        and swimming{' '}
        <span role="img" aria-label="swimming">
          🏊
        </span>
        <br />I don't enjoy: driving
      </p>
      <p>
        Feel free to shoot me an <a href="mailto:hi@qiuiqi.art">email</a> to share your thoughts!
      </p>
      <p>
        For children's book illustration, please contact my agent{' '}
        <em className={styles.em}>
          Natalie M. Lakosil: <a href="mailto:natalie@lookingglasslit.com">natalie@lookingglasslit.com</a>
        </em>
        .
      </p>
    </article>
  </section>
);

export default About;
