type EventCategory = 'Navigation' | 'Outbound' | 'Affiliate Link';
type EventParameters = {
  event_category: EventCategory;
  event_label: string;
  value?: number;
  send_to?: string;
};

declare function gtag(type: 'config', id: string, config: { page_path: string }): void;
declare function gtag(type: 'event', action: string, event: EventParameters): void;

const GA_TRACKING_ID = 'UA-127837925-1';

export function trackPageview(page_path: string): void {
  gtag('config', GA_TRACKING_ID, { page_path });
}

export function trackEvent(action: string, event_category: EventCategory, event_label: string, value?: number): void {
  gtag('event', action, { event_category, event_label, value, send_to: GA_TRACKING_ID });
}
