import styles from './ExternalLink.module.css';

import LinkIcon from './LinkIcon';

import { trackEvent } from '../../utils/gtag';

function ExternalLink({ href, children }: { href: string, children: string }) {
  return (
    <a
      href={href}
      className={styles.host}
      onClick={() => trackEvent('Click - ' + children, 'Outbound', children)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
      <LinkIcon />
    </a>
  );
}

export default ExternalLink;
