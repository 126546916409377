// @flow strict

import { PureComponent } from 'react';
import { Link } from 'react-router-dom';

// styles
import './Grid.css';

// components
import Image from '../Image';

// data
import illustrations from '../../data/illustrations.json';

type Props = {
  category: string;
  categorySelected: (category: string | null) => void;
};

class Grid extends PureComponent<Props> {
  componentDidMount() {
    this.props.categorySelected(this.props.category || null);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.category !== this.props.category) {
      this.props.categorySelected(this.props.category || null);
    }
  }

  render() {
    const { category } = this.props;
    return (
      <section id="Grid">
        {illustrations
          .filter((illustration) => category === undefined || category === illustration.category)
          .map((illustration) => (
            <Link
              key={illustration.name}
              to={`/${illustration.category}/${illustration.name}/`}
              style={{ ['--span' as any]: illustration.span }}
            >
              <Image {...illustration} width={512 * (illustration.span ?? 1)} height={512} />
              {Array.from({ length: illustration.span ?? 1 }).map((_, key) => (
                <div className="square-placeholder" key={key} aria-hidden />
              ))}
            </Link>
          ))}
      </section>
    );
  }
}

export default Grid;
