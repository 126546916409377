import { Redirect } from 'react-router-dom';

// styles
import styles from './Book.module.css';

import Image from '../Image';

// data
import books from '../../data/books.json';

import { trackEvent } from '../../utils/gtag';
import { Helmet } from '../Helmet/Helmet';

const Amazon = {
  ca: 'Amazon CA',
  us: 'Amazon US',
  uk: 'Amazon UK',
};

function trackAmazon(country: keyof typeof Amazon, book: string) {
  trackEvent('Click - ' + Amazon[country], 'Affiliate Link', book);
}

function trackReviewsClick(sourceName: string) {
  trackEvent('Click - Reviews', 'Outbound', sourceName);
}

function trackPublisherClick(book: string) {
  trackEvent('Click - Publisher', 'Outbound', book);
}

function Book({ id }: { id: string }) {
  const book = books.find((book) => id === book.id);

  if (!book) {
    return <Redirect to="/books/"></Redirect>;
  }

  return (
    <article itemScope itemType="https://schema.org/Book" className={styles.article}>
      <Helmet title={book.title} description={book.description.join(' ')} />

      <h1 itemProp="name" className={styles.h1}>
        {book.title}
      </h1>

      <section className={styles.publishingInfo}>
        <span hidden itemProp="illustrator" itemScope itemType="https://schema.org/Person">
          <span itemProp="name">Rachel Qiuqi</span>
          {` – `}
        </span>
        <a
          itemProp="publisher"
          itemScope
          itemType="https://schema.org/Organization"
          href={book.publisherLink}
          target="_blank"
          onClick={() => trackPublisherClick(id)}
        >
          <span itemProp="name">{book.publisher}</span>
        </a>{' '}
        <span itemProp="copyrightYear">{book.year}</span>
      </section>

      <Image category="books" name={`${id}-${book.hasOnlyCover ? 'cover' : 'inside'}`} alt="" height={600} />

      {(book.reviews?.length ?? 0 > 0) && (
        <section className={styles.reviews}>
          {book.reviews?.map(({ quote, source, url }) => (
            <div key={url} itemScope itemType="https://schema.org/CriticReview">
              <blockquote cite={url} itemProp="reviewBody">
                {quote}
              </blockquote>
              —
              <a
                target="_blank"
                href={url}
                itemProp="author"
                itemScope
                itemType="https://schema.org/Organization"
                onClick={() => trackReviewsClick(source)}
              >
                <cite itemProp="name">{source}</cite>
              </a>
            </div>
          ))}
        </section>
      )}

      {book.description.length > 0 && (
        <section className={styles.description}>
          {book.description.map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
          ))}
        </section>
      )}

      {book.amazon?.length > 0 && (
        <section>
          <strong>Available on:</strong>
          <ul className={styles.amazon}>
            {book.amazon.map(({ country, url }) => (
              <li key={country}>
                <a
                  href={url}
                  target="_blank"
                  onClick={() => trackAmazon(country, id)}
                  title="Purchasing from this link would directly support me ❤️"
                >
                  {Amazon[country]}
                </a>
              </li>
            ))}
          </ul>
        </section>
      )}
    </article>
  );
}

export default Book;
