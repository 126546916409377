// @flow strict

import { Link } from 'react-router-dom';

import './SingleImage.css';

import Image from '../Image';

import { ReactComponent as Arrow } from '../../svgs/arrow.svg';

// data
import illustrations from '../../data/illustrations';

import { trackEvent } from '../../utils/gtag';

type Props = {
  categorySelected: string | null,
  category: string,
  name: string
};

const SingleImage = ({ categorySelected, ...forImage }: Props) => {
  const currentList = illustrations.filter(
    ({ category }) => categorySelected === null || categorySelected === category
  );

  const currentIndex = currentList.findIndex(({ name }) => forImage.name === name);
  const previousIndex = (currentIndex - 1 + currentList.length) % currentList.length;
  const nextIndex = (currentIndex + 1) % currentList.length;

  const prev = currentList[previousIndex];
  const next = currentList[nextIndex];

  return (
    <section id="SingleImage">
      <header>
        <Link
          className="back"
          to={`/${categorySelected ? categorySelected + '/' : ''}`}
          onClick={() => trackEvent('Click - Back', 'Navigation', categorySelected || 'Home')}
        >
          Back to {categorySelected || 'all'}
        </Link>
      </header>
      <section className="content">
        <Link
          className="arrow"
          to={`/${prev.category}/${prev.name}/`}
          onClick={() => trackEvent('Click - Arrow', 'Navigation', 'Previous')}
        >
          <Arrow className="previous" />
        </Link>
        <Image {...forImage} best />
        <Link
          className="arrow"
          to={`/${next.category}/${next.name}/`}
          onClick={() => trackEvent('Click - Arrow', 'Navigation', 'Next')}
        >
          <Arrow className="next" />
        </Link>
      </section>
    </section>
  );
};

export default SingleImage;
