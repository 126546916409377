import { Link } from 'react-router-dom';

import styles from './BooksGrid.module.css';

import books from '../../data/books.json';

import Image from '../Image';
import { Helmet, siteDescription } from '../Helmet/Helmet';

export default function BooksGrid() {
  return (
    <section className={styles.grid}>
      <Helmet title="Books" description={`With ${books.length} books published, ${siteDescription}`} />

      {books.map(({ id, title }) => (
        <Link key={id} to={`/books/${id}/`}>
          <Image category="books" name={id + '-cover'} width={512} height={512} fit />
          <h2 className={styles.title}>{title}</h2>
        </Link>
      ))}
    </section>
  );
}
