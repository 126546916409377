// @flow strict

import { BrowserRouter } from 'react-router-dom';

import './App.css';

import Shell from './components/Shell';

const App = () => (
  <BrowserRouter>
    <Shell />
  </BrowserRouter>
);

export default App;
