import { type SVGProps } from 'react';

const LinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 13.45 13.45" {...props}>
    <title>External Link Icon</title>
    <g className="arrow-icon" strokeWidth={1.2} fill="none">
      <path className="arrow-line" d="M6.72,6.73l8.51-8.51" />
      <path className="arrow-head" d="M8.65,-1.8H15.23V4.78" strokeLinejoin="round" />
      <path d="M12.85,6.73v4.64a1.48,1.48,0,0,1-1.48,1.48H2.08A1.48,1.48,0,0,1,.6,11.37V2.09A1.48,1.48,0,0,1,2.08.61H6.72" />
    </g>
  </svg>
);

export default LinkIcon;
