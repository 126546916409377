// @flow strict

import { Component, Fragment } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';

// styles

// components
import Header from '../Header';
import About from '../About';
import Grid from '../Grid';
import SingleImage from '../SingleImage';
import Book from '../Book';
import BooksGrid from '../BooksGrid';
import { Helmet } from '../Helmet/Helmet';

import { trackPageview } from '../../utils/gtag';

type State = { category: string | null };

class Shell extends Component<ContextRouter, State> {
  state = { category: null };

  componentDidMount() {
    trackPageview(this.props.location.pathname);
  }

  componentDidUpdate(prevProps: ContextRouter) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      trackPageview(this.props.location.pathname);

      if (this.props.location.pathname.startsWith('/about') || this.props.location.pathname.startsWith('/books')) {
        this.setState({ category: null });
      }
    }
  }

  categorySelected = (category: string | null) => this.setState({ category });

  renderGrid = ({ match }: ContextRouter) => (
    <Grid category={match.params.category} categorySelected={this.categorySelected} />
  );

  renderSingleImage = ({ match }: ContextRouter) => (
    <SingleImage category={match.params.category} name={match.params.name} categorySelected={this.state.category} />
  );

  renderBook = ({ match }) => <Book id={match.params.id} />;

  render() {
    return (
      <Fragment>
        <Helmet />
        <Header categorySelected={this.state.category} />
        <main>
          <Switch>
            <Route exact path="/" render={this.renderGrid} />
            <Route exact path="/about/" component={About} />
            <Route exact path="/books/" component={BooksGrid} />
            <Route exact path="/books/:id/" render={this.renderBook} />
            <Route exact path="/:category/" render={this.renderGrid} />
            <Route exact path="/:category/:name/" render={this.renderSingleImage} />
          </Switch>
        </main>
      </Fragment>
    );
  }
}

export default withRouter(Shell);
