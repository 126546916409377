import { Helmet as ReactHelmet } from 'react-helmet';

export const siteTitle = 'Rachel Qiuqi – Children’s-Book Illustrator';
export const siteDescription =
  'Rachel Qiuqi is an illustrator and author passionate about storytelling for children. She started drawing when she was little and has been drawing with the same naïveté to this day. Her delightful work is not only inspiring for kids, but also grown-ups too.';

export function Helmet({ title, description }: { title?: string; description?: string }) {
  const pageTitle = title ? `${title} by ${siteTitle}` : siteTitle;
  const pageDescription = description ?? siteDescription;
  return (
    <ReactHelmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      <meta name="description" content={pageDescription} />
      <meta property="og:description" content={pageDescription} />
    </ReactHelmet>
  );
}
