import { Link, NavLink } from 'react-router-dom';

// styles
import styles from './Header.module.css';

import { ReactComponent as ClearIcon } from '../../svgs/clear.svg';
import { ReactComponent as TwitterIcon } from '../../svgs/twitter.svg';
import ExternalLink from '../ExternalLink';

import signature1x from './signature@1x.png';
import signature2x from './signature@2x.png';
import signature3x from './signature@3x.png';
import insta from './insta.png';
import gram from './gram.png';

// data
import categories from '../../data/categories.json';

import { trackEvent } from '../../utils/gtag';

type Props = { categorySelected: string | null };

const categoriesInDisplay = ['animals', 'characters', 'sketches'];

const Header = ({ categorySelected }: Props) => (
  <header className={styles.host}>
    <header>
      <Link
        to="/"
        className={styles.logoLink}
        onClick={() => trackEvent('Click - Logo', 'Navigation', 'Home')}
        title="Go to home page"
      >
        <img
          className={styles.logo}
          alt="Rachel Qiuqi"
          width={96}
          height={76}
          src={signature1x}
          srcSet={`${signature1x} 1x, ${signature2x} 2x, ${signature3x} 3x`}
        />
      </Link>
      <a href="mailto:hi@qiuqi.art" onClick={() => trackEvent('Click - Email', 'Outbound', 'Email')}>
        hi@qiuqi.art
      </a>
      <a
        className={styles.instagramLink}
        href="https://www.instagram.com/rachelqiuqi/"
        title="Instagram"
        target="_blank"
        onClick={() => trackEvent('Click - Social', 'Outbound', 'Instagram')}
      >
        <img className={styles.instagramIcon} src={insta} alt="" />
        <img className={styles.instagramIcon} src={gram} alt="" />
      </a>
      <a
        className={styles.twitterLink}
        href="https://twitter.com/rachelqiuqi"
        title="Twitter"
        target="_blank"
        onClick={() => trackEvent('Click - Social', 'Outbound', 'Twitter')}
      >
        <TwitterIcon />
      </a>
    </header>
    <nav className={styles.about}>
      <ul>
        <li>
          <NavLink
            activeClassName={styles.active}
            to="/books/"
            onClick={() => trackEvent('Click - Books', 'Navigation', 'Books')}
          >
            Books
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName={styles.active}
            to="/about/"
            onClick={() => trackEvent('Click - About', 'Navigation', 'About')}
          >
            About
          </NavLink>
        </li>
        <li>
          <ExternalLink href="https://society6.com/ipuip">Shop</ExternalLink>
        </li>
      </ul>
    </nav>
    <nav className={styles.categories}>
      <ul>
        {categories
          .filter((category) => categoriesInDisplay.includes(category.id))
          .map((category) => (
            <li key={category.id} className={styles.filterItem}>
              <NavLink
                to={`/${category.id}/`}
                className={category.id === categorySelected ? styles.active : undefined}
                onClick={() => trackEvent('Click - Category Filter', 'Navigation', category.name)}
              >
                {category.name}
              </NavLink>
              <Link
                to="/"
                className={styles.clearButton}
                onClick={() => trackEvent('Click - Category Filter', 'Navigation', 'Home')}
              >
                <button>
                  <ClearIcon className={styles.clearIcon} />
                </button>
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  </header>
);

export default Header;
